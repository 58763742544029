import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { Button } from 'reactstrap';
import { ArrowUp } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Footer = ({
  hideScrollToTop,
  footerType,
  backgroundColor,
  isScreenSaver,
}) => {
  const footerTypeArr = ['sticky', 'static', 'hidden'];

  return (
    <footer
      className={classnames(
        `d-flex justify-content-center ${!isScreenSaver &&
          'footer'} text-center footer-light p-1`,
        {
          'footer-static':
            footerType === 'static' || !footerTypeArr.includes(footerType),
          'd-none': footerType === 'hidden',
        },
      )}
    >
      <div
        className={`py-1 px-3 px-sm-4 px-md-5 ${
          backgroundColor ? backgroundColor : 'bg-primary'
        } rounded text-white`}
      >
        <div className="d-block justify-content-center">
          <FormattedMessage
            id="poweredByLabel"
            values={{
              servierBar: (
                <a
                  href="https://servier.bar/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="m-0"
                >
                  <img
                    src="/assets/logos/sb-logo-white.svg"
                    className="d-inline"
                    style={{
                      height: '33px',
                      marginTop: '-7px',
                      marginLeft: '-3px',
                    }}
                  />
                </a>
              ),
            }}
          />
        </div>
        {!isScreenSaver && (
          <div className="copyright d-block font-small-2 mt-1">
            © {new Date().getFullYear()} servier.bar
            <br />
            <FormattedMessage
              id="footerLegalLabels"
              values={{
                privacy_link: (text) => (
                  <a
                    href="https://servier.bar/datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="white"
                  >
                    {text}
                  </a>
                ),
                agb_link: (text) => (
                  <a
                    href="https://servier.bar/agb/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="white"
                  >
                    {text}
                  </a>
                ),
                imprint_link: (text) => (
                  <a
                    href="https://servier.bar/impressum/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="white"
                  >
                    {text}
                  </a>
                ),
              }}
            />
          </div>
        )}
      </div>

      {!hideScrollToTop && (
        <ScrollToTop showUnder={800}>
          <Button color="secondary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      )}
    </footer>
  );
};

Footer.propTypes = {
  hideScrollToTop: PropTypes.bool,
  footerType: PropTypes.string,
};

const mapStateToProps = (state) => ({
  hideScrollToTop: state.customizer.hideScrollToTop,
  footerType: state.customizer.footerType,
});

export default connect(mapStateToProps)(Footer);
